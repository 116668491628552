import React from 'react'
import Layout from '../../components/Default/Layout'
import PageHeader from '../../components/PageHeader/PageHeader'
import '../../components/ParagraphUnderPhoto/styles.css'
import { graphql } from 'gatsby'
import './styles.css'
import FormAjax from './../../components/Default/FormSimpleAjax'
import { UnderlineYellow } from './../../components/Typography'

const Kontakt = ({ data: { page, image1 } }) => {
  const { frontmatter } = page
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
    >
      <PageHeader
        title={frontmatter.title}
        backgroundImage={frontmatter.image.childImageSharp.fluid}
      />
      <div className="Paragraph--Wrapper container Contact">
        <p>{frontmatter.description}</p>
        <h2>
          Nabízíme poradenství v oblasti přímých a nepřímých daní a účetnictví.
        </h2>
        <p>
          Máte zájem o dlouhodobou spolupráci, žádáte o službu, dočasné
          poradenství nebo snad jen konzultaci?
        </p>
        <h2>Napište nám níže!</h2>
        <p>
          Obratem se vám ozveme s našimi obchodními podmínkami včetně hodinových
          sazeb. Pokud s podmínkami budete souhlasit, pozveme vás k nám na
          seznamovací schůzku nebo se s vámi spojíme online. Jsme poradenskou
          společností a našimi klienty jsou především právnické osoby/firmy
          menší a střední velikosti, pro které je outsourcing účetních a
          daňových služeb tím nejefektivnějším řešením. Mezi našimi zákazníky
          najdete také společnosti z kategorie velkých účetních jednotek.
          Poskytujeme jak vedení účetnictví, zpracování mezd a přípravu daňových
          přiznání, tak specializované služby jako účetní reporting a
          controlling, převodní ceny, poradenství při přeměnách společnosti,
          přesunech majetku a vypracování znaleckých posudků.
        </p>
        <div className="container Contact--BlueBackground" id="formAnchor">
          <div>
            <h2 className="h2white">Napište nám</h2>
            <UnderlineYellow />
            <FormAjax name="Contact" />
          </div>
        </div>
        <h2>Napište nám přímo</h2>
        <div className="row">
          <div className="col-lg-6">
            <div className="Card">
              <div className="Inner row align-items-center">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <img
                    className="head"
                    src="/images/team/lukas_eisenwort.jpg"
                    alt="lukas-eisenwort"
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div>
                    <img
                      className="logo"
                      src="/images/logo-white.png"
                      alt="logo-white"
                    />
                    <p className="Name">Lukáš Eisenwort</p>
                    <p className="Position">
                      partner, daňový poradce / Steuerberater / tax consultant
                    </p>
                    <div className="PhoneAndMail">
                      <a href="tel:+420273139330">+ 420 273 139 330</a>
                      <br />
                      <a
                        href="mailto:&#108;&#117;&#107;&#97;&#115;&#46;&#101;&#105;&#115;&#101;&#110;&#119;&#111;&#114;&#116;&#64;&#101;&#107;&#112;&#46;&#99;&#122;&#10;"
                      >
                        &#108;&#117;&#107;&#97;&#115;&#46;&#101;&#105;&#115;&#101;&#110;&#119;&#111;&#114;&#116;&#64;&#101;&#107;&#112;&#46;&#99;&#122;&#10;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="Card">
              <div className="Inner row align-items-center">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <img
                    className="head"
                    src="/images/team/jan_kotala.jpg"
                    alt="jan-kotala"
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div>
                    <img
                      className="logo"
                      src="/images/logo-white.png"
                      alt="logo-white"
                    />
                    <p className="Name">Jan Kotala</p>
                    <p className="Position">
                      partner, daňový poradce / Steuerberater / tax consultant
                    </p>
                    <div className="PhoneAndMail">
                      <a href="tel:+420273139330">+ 420 273 139 330</a>
                      <br />
                      <a href="mailto:&#106;&#97;&#110;&#46;&#107;&#111;&#116;&#97;&#108;&#97;&#64;&#101;&#107;&#112;&#46;&#99;&#122;">
                        &#106;&#97;&#110;&#46;&#107;&#111;&#116;&#97;&#108;&#97;&#64;&#101;&#107;&#112;&#46;&#99;&#122;
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 id="nasePobocky">Naše pobočky</h2>
        <UnderlineYellow />

        <div className="row" style={{ marginTop: '64px', color: '#003A56' }}>
          <div className="col-lg-6">
            <iframe
              width="400"
              height="280"
              style={{ borderRadius: '22px' }}
              src="https://maps.google.com/maps?q=EK%20Partners%2C%20s.r.o.%2C%20Senov%C3%A1%C5%BEn%C3%A9%20n%C3%A1m%C4%9Bst%C3%AD%2C%20Praha%201-Nov%C3%A9%20M%C4%9Bsto&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              className="Contact--Map"
            ></iframe>
            <div className="Contact--Map--Title">Praha:</div>
            <div>
              <a
                href="http://maps.google.com/?q=EKP Advisory, s.r.o., Senovážné náměstí, Praha 1-Nové Město"
                target="_blank"
                rel="noopener noreferrer"
              >
                Senovážné náměstí 8, 110 00 <br />
                Praha 1 <br />
              </a>
              <a href="tel:+420273139330">Tel.: +420 273 139 330</a>
            </div>
          </div>
          <div className="col-lg-6">
            <iframe
              width="400"
              height="280"
              style={{ borderRadius: '22px' }}
              src="https://maps.google.com/maps?q=EK%20Partners%2C%20s.r.o.%2C%20Kosm%C3%A1kova%2C%20Jihlava&t=&z=17&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              className="Contact--Map"
            ></iframe>

            <div className="Contact--Map--Title">Jihlava:</div>
            <div>
              <a
                href="http://maps.google.com/?q=EKP Advisory, s.r.o., Kosmákova, Jihlava"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telečská 1724/13, 586 01 <br />
                Jihlava <br />
              </a>
              <a href="tel:+420560000086">Tel.: +420 560 000 086</a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Kontakt

export const pageQuery = graphql`
  query Kontakt {
    page: markdownRemark(frontmatter: { slug: { eq: "kontakt" } }) {
      ...Meta
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
